<template>
  <div>
    <Divider dashed><span class="divider-text">待办任务汇总</span></Divider>
    <Row class="table-title p-t-2">
      <i-col span="12" class="p-l-5 p-t-5 p-b-5">任务类型</i-col>
      <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5">数量</i-col>
    </Row>

    <Row v-for="(item, index) in taskList" :key="index" :class="(index % 2 === 0) ? 'table-row-1' : 'table-row-2'">
      <i-col span="12" class="p-l-5 p-t-5 p-b-5">{{ item.name }}</i-col>
      <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5">{{ item.quantity }}</i-col>
    </Row>
    <!-- <Divider><span class="divider-text">撤销申请汇总</span></Divider> -->

    <Divider dashed><span class="divider-text">其他任务操作</span></Divider>
    <!-- <div class="m-b-5">
      <Button
        type="primary"
        long
        @click="handleCreateTask"
      >新增任务</Button>
    </div>
    <div class="m-b-5">
      <Button
        type="primary"
        long
        @click="handleUninstallTask"
      >下刊画面填充</Button>
    </div> -->
    <div class="m-b-5">
      <Button type="primary" size="small" long @click="handleApplicationCancel">
        <Badge :count="revokeTotal" :offset="[0, -10]">
          处理撤销申请
        </Badge>
      </Button>
    </div>
    <div class="m-b-5">
      <Button type="primary" size="small" long @click="handlePendingTask">
        处理挂起任务
      </Button>
    </div>
    <div class="m-b-5">
      <Button type="primary" size="small" long @click="handleViewProgramList">
        电子媒体节目单
      </Button>
    </div>
    <div class="m-b-5" v-if="isAuth('task_index_dynamicboard')">
      <Button type="warning" size="small" long @click="handleDynamicBoard">动态作业看板</Button>
    </div>

  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { getTaskTypeStatistic } from '@/api/msp/task'
import { getApplyRevokeQuantity } from '@/api/msp/terminate'

export default {
  mixins: [sysMixins],
  data () {
    return {
      taskList: [],
      revokeTotal: 0
    }
  },
  created () {
    this.initPageData()
    this.getRevokeCount()
  },
  methods: {
    initPageData () {
      getTaskTypeStatistic().then(res => {
        this.taskList = res
      })
    },
    // /**
    //  * 新增任务
    //  */
    // handleCreateTask () {
    //   this.setShowRightBlock(false)
    //   this.setBottomComponent('NewTaskResource')

    //   // 设置头部路由标签
    //   this.setLinkTagArray({ key: 'taskCreate', value: '新增任务' })
    //   this.setLinkRouterMaps(['taskCreate', () => { this.setBottomComponent('NewTaskResource') }])
    //   this.setActivedTagName('taskCreate')
    // },
    /**
     * 下刊画面填充
     */
    handleUninstallTask () {
      this.handleUninstallPage()

      // 设置头部路由标签
      this.setLinkTagArray({ key: 'taskUninstall', value: '下刊画面填充' })
      this.setLinkRouterMaps(['taskUninstall', () => { this.handleUninstallPage() }])
      this.setActivedTagName('taskUninstall')
    },
    /**
     * 处理下刊画面填充页面渲染
     */
    handleUninstallPage () {
      this.setBottomComponent('UninstallTable')
      this.setRightComponent('ResourceStatistics')
      this.setLeftComponent('UninstallScreen')
      this.setShowLeftBlock(true)
      this.setShowRightBlock(true)
    },

    /**
     * 动态作业看板
     */
    handleDynamicBoard () {
      this.handleDynamicBoardPage()

      // 设置头部路由标签
      this.setLinkTagArray({ key: 'dynamicboard', value: '动态作业看板' })
      this.setLinkRouterMaps(['dynamicboard', () => { this.handleDynamicBoardPage() }])
      this.setActivedTagName('dynamicboard')
    },
    /**
     * 处理动态作业看板页面渲染
     */
    handleDynamicBoardPage () {
      this.setBottomComponent('boardList')
      this.setRightComponent('boardTaskStatistics')
      this.setLeftComponent('WorkList')
      this.setShowLeftBlock(true)
      this.setShowRightBlock(true)
    },

    handleApplicationCancel () {
      this.handelRevokePage()
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'taskCancel', value: '撤销申请列表' })
      this.setLinkRouterMaps(['taskCancel', () => { this.handelRevokePage() }])
      this.setActivedTagName('taskCancel')
    },
    /**
     * 处理取消任务界面
     */
    handelRevokePage () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setLeftComponent('')
      this.setBottomComponent('CancelTaskTable')
      this.setRightComponent('')
    },
    /**
     * 获取撤销任务总量
     */
    getRevokeCount () {
      getApplyRevokeQuantity({ operRole: 1 }).then(res => {
        if (res && !res.errcode) {
          this.revokeTotal = res
        }
      })
    },
    /**
     * 跳转挂起任务列表
     */
    handlePendingTask () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setLeftComponent('')
      this.setBottomComponent('HangupTaskTable')
      this.setRightComponent('')

      // 设置头部路由标签
      this.setLinkTagArray({ key: 'taskHangup', value: '挂起任务处理' })
      this.setLinkRouterMaps(['taskHangup', () => {
        this.setShowLeftBlock(false)
        this.setShowRightBlock(false)
        this.setLeftComponent('')
        this.setBottomComponent('HangupTaskTable')
        this.setRightComponent('')
      }])
      this.setActivedTagName('taskHangup')
    },
    /**
     * 浏览电子媒体节目单
     */
    handleViewProgramList () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setLeftComponent('')
      this.setBottomComponent('EleProgramList')
      this.setRightComponent('')

      // 设置头部路由标签
      this.setLinkTagArray({ key: 'eleProgramList', value: '电子媒体节目单' })
      this.setLinkRouterMaps(['eleProgramList', () => {
        this.setShowLeftBlock(false)
        this.setShowRightBlock(false)
        this.setLeftComponent('')
        this.setBottomComponent('EleProgramList')
        this.setRightComponent('')
      }])
      this.setActivedTagName('eleProgramList')
    }
  }
}
</script>
