import request from '@/utils/requestV2'
const qs = require('qs')

// 任务数据统计
export function getQuantity (data) {
  return request({
    url: '/ooh-msp/v2/task/getquantity',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 任务的按供应商分布统计
export function getTaskItemCompanyStatistic (data) {
  return request({
    url: '/ooh-msp/v2/task/gettaskitemcompanystatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 任务按线路、区域及站点统计
export function getTaskItemGatherStatistic (data) {
  return request({
    url: '/ooh-msp/v2/task/gettaskitemgatherstatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 任务的按状态分布统计
export function getTaskItemStatusStatistic (data) {
  return request({
    url: '/ooh-msp/v2/task/gettaskitemstatusstatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 分页获取任务类型
export function getTaskPage (data) {
  return request({
    url: '/ooh-msp/v2/task/gettaskpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取任务(巡检、临时任务、自定义上刊)
export function getTaskPageWithCompany (data) {
  return request({
    url: '/ooh-msp/v2/task/getTaskPageWithCompany',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取任务
export function getTaskSortPage (data) {
  return request({
    url: '/ooh-msp/v2/task/gettasksortpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 待办任务按类型统计
export function getTaskTypeStatistic (data) {
  return request({
    url: '/ooh-msp/v2/task/gettasktypestatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除任务
export function deleteTask (data) {
  return request({
    url: '/ooh-msp/v2/task/deletetask',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取需求产品列表(只有来自于订单才有值)
 * @param {Object} data
 */
export function getProductList (data) {
  return request(
    {
      url: '/ooh-msp/v2/task/getproductlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 获取上刊需求资源类型的资产站点统计
 * @param {Object} data
 */
export function getResourceParameter (data) {
  return request(
    {
      url: '/ooh-msp/v2/task/getresourceparameter',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

// 获取运维审批任务
export function getApproveTask (data) {
  return request({
    url: '/ooh-msp/v2/task/getapprovetask',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取运维审批任务详情
export function getApproveTaskDetail (data) {
  return request({
    url: '/ooh-msp/v2/task/getapprovetaskdetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取运维审批任务费用汇总
export function getTaskItemCharge (data) {
  return request({
    url: '/ooh-msp/v2/task/gettaskitemcharge',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取下刊任务列表
export function getUninstallTaskPage (data) {
  return request({
    url: '/ooh-msp/v2/task/getuninstalltaskpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取设备列表
export function getDeviceList (data) {
  return request({
    url: '/ooh-msp/v2/task/getdevicelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取上刊设置列表
export function getMspOrderPage (data) {
  return request({
    url: '/ooh-msp/v2/task/getmsporderpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 临近上刊订单数量、资源数量
export function getNearDemandQuantity (data) {
  return request({
    url: '/ooh-msp/v2/task/getneardemandquantity',
    method: 'post',
    data: qs.stringify(data)
  })
}
